import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BreadCrumbs from "../components/bread-crumbs"

const breadCrumbsList = [
  {
    title: "Home",
    link: "/",
  },
]

const MagicLinkError = () => {
  useEffect(() => {
    window.analytics.page("Magic link Error")
  }, [])

  return (
    <Layout>
      <div className="not-found-container magic-link-container">
        <div className="inner-container-magic">
          <h2 className="ml-heading">
            Whoops, seems your magic link is expired or invalid.
          </h2>
          <p className="nf-parah">
            Magic links expire after 30 Minutes. If your link expires, simply
            enter your email address in the App and click send again to receive
            a new link.
          </p>
          <p className="nf-parah">See you soon,</p>
          <p className="nf-parah">Confidant Health</p>
          <p className="nf-parah">
            Have any questions? Please email{" "}
            <a className="mg-email" href="mailto:admin@confidanthealth.com">
              admin@confidanthealth.com
            </a>
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default MagicLinkError
